require('./alerts/alerts_list_controller.js');
require('./alerts/alerts_most_recent_controller.js');
require('./alerts/alerts_overview_controller.js');
require('./alerts/edit_alert_dialog_controller.js');
require('./buttons/button_group_controller.js');
require('./catalog/catalog_item_form_controller.js');
require('./cloud_object_store_container/cloud_object_store_container_form_controller.js');
require('./cloud_volume/cloud_volume_form_controller.js');
require('./configuration_manager_form_controller.js');
require('./container_dashboard/card/card-module.js');
require('./container_dashboard/card/heatmaps/heatmaps-card-directive.js');
require('./container_dashboard/container_project_dashboard_controller.js');
require('./container_dashboard/util.js');
require('./container_dashboard/util/dashboard-utils-factory.js');
require('./credentials/credentials_controller.js');
require('./dialog_editor/dialog_editor_controller.js');
require('./dialog_user/dialog_user_controller.js');
require('./dialog_user/dialog_user_reconfigure_controller.js');
require('./ems_common/aggregate_status_card_controller.js');
require('./ems_container_dashboard/aggregate_status_card_controller.js');
require('./ems_container_dashboard/heatmap_controller.js');
require('./ems_infra_dashboard/heatmap_controller.js');
require('./ems_keypair/ems_keypair_controller.js');
require('./ems_storage_dashboard/heatmap_controller.js');
require('./error_modal_controller.js');
require('./fonticon_picker_controller.js');
require('./host/host_form_controller.js');
require('./host_aggregate/host_aggregate_form_controller.js');
require('./miq_ae_class/ae_inline_method_selection_controller.js');
require('./mixins/charts-mixin.js');
require('./ops/pglogical_replication_form_controller.js');
require('./ops/tenant_quota_form_controller.js');
require('./playbook-reusable-code-mixin.js');
require('./reconfigure/reconfigure_form_controller.js');
require('./schedule/schedule_form_controller.js');
require('./timeline/timeline_options_controller.js');
require('./tree_view_controller.js');
require('./vm_cloud/vm_cloud_associate_floating_ip_form_controller.js');
require('./vm_cloud/vm_cloud_attach_form_controller.js');
require('./vm_cloud/vm_cloud_detach_form_controller.js');
require('./vm_cloud/vm_cloud_disassociate_floating_ip_form_controller.js');
require('./vm_cloud/vm_cloud_live_migrate_form_controller.js');
require('./vm_cloud/vm_cloud_resize_form_controller.js');
